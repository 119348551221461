import { useMemo } from 'react';

import { GetProps, Stack, parseRootChildren, styled, useProps, withStaticProperties } from '@arrived/bricks';

import { HEADER_SECONDARY_NAME } from './constants';
import { HeaderStyledContext } from './HeaderStyledContext';
import { Main } from './Main';
import { Secondary } from './secondary';

const HeaderFrame = styled(Stack, {
  context: HeaderStyledContext,

  width: '100%',

  variants: {
    alignment: {
      left: {},
      center: {},
    },
    orientation: {
      column: {},
      row: { row: true, minHeight: 650 },
    },
    split: {
      true: {},
      false: {},
    },
  } as const,
});

type HeaderProps = Omit<GetProps<typeof HeaderFrame>, 'split'>;

export const Header = withStaticProperties(
  HeaderFrame.styleable<HeaderProps>((props, ref) => {
    const { children: childrenIn, ...rest } = useProps(props);

    const { children, [HEADER_SECONDARY_NAME]: Secondary } = useMemo(
      () => parseRootChildren(childrenIn, [HEADER_SECONDARY_NAME]),
      [childrenIn],
    );

    return (
      <HeaderFrame ref={ref} split={Secondary != null} {...rest}>
        {children}
        {Secondary}
      </HeaderFrame>
    );
  }),
  { Main, Secondary },
);
