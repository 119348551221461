import * as React from 'react';
import { memo } from 'react';

import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function StarInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <G clip-path="url(#a)">
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13.324 6.54 10 .844 6.676 6.542.93 7.978l3.826 4.304-.954 6.683L10 16.105l6.198 2.862-.955-6.684 3.826-4.305-5.745-1.437Z"
          clipRule="evenodd"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h20v20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const StarIcon = memo<IconProps>(withIconTheme(StarInternal));
