import { styled, useProps } from '@arrived/bricks';

import { Email } from '../../components';
import { FooterStyledContext } from './FooterStyledContext';

const StyledEmail = styled(Email, {
  context: FooterStyledContext,

  gap: '$3',

  variants: {
    variant: {
      inline: { w: 320 },
      condensed: { w: '100%' },
      stacked: { row: true, w: '100%', maxWidth: 650 },
    },
  } as const,
});

const StyledEmailInput = styled(Email.Input, {
  context: FooterStyledContext,

  variants: {
    variant: {
      inline: {},
      condensed: {},
      stacked: { containerProps: { flex: 1 } },
    },
  } as const,
});

const StyledEmailCTA = styled(Email.CTA, {
  context: FooterStyledContext,

  variants: {
    backdrop: {
      default: {},
      coins: {},
      image: { variant: 'emphasized' },
    },
  } as const,
});

export const Signup = StyledEmail.styleable((propsIn, ref) => {
  const props = useProps(propsIn);

  return (
    <StyledEmail ref={ref} {...props}>
      <StyledEmailInput placeholder="Your email" />
      <StyledEmailCTA>Get Started</StyledEmailCTA>
    </StyledEmail>
  );
});
