import { Fragment } from 'react';

import { styled } from '@arrived/bricks';

import { PRESS_ARTICLE_NAME } from './constants';

export interface ArticleProps {
  content: string;
  image: string;
}

export const Article = styled(Fragment, {
  name: PRESS_ARTICLE_NAME,

  variants: {
    content: {
      ':string': () => ({}),
    },
    image: {
      ':string': () => ({}),
    },
  } as const,
});
