import { Controller, useFormContext } from 'react-hook-form';

import { Input as BricksInput, styled, useComposedRefs, useProps } from '@arrived/bricks';

import { EMAIL_INPUT_NAME } from './constants';

const InputFrame = styled(BricksInput, {
  name: EMAIL_INPUT_NAME,
});

export const Input = InputFrame.styleable((propsIn, ref) => {
  const props = useProps(propsIn);

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="email"
      render={({ field: { value, onChange, onBlur, ref: fieldRef }, fieldState: { error } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const composedRefs = useComposedRefs(ref, fieldRef);

        return (
          <InputFrame
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            ref={composedRefs}
            isInvalid={Boolean(error)}
            {...props}
          />
        );
      }}
    />
  );
});
