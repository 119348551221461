import { PixelRatio } from 'react-native';

import { Background as BricksBackground, Image, Stack, styled } from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

import { FooterStyledContext } from './FooterStyledContext';

const Default = styled(BricksBackground.Noise, {
  emphasized: true,

  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const coins1Source = ikClient.url({
  path: '/features/landing-pages/footer/coin-1.png',
  transformation: [
    {
      width: PixelRatio.getPixelSizeForLayoutSize(200).toString(),
      height: PixelRatio.getPixelSizeForLayoutSize(200).toString(),
    },
  ],
});

const coins2Source = ikClient.url({
  path: '/features/landing-pages/footer/coin-2.png',
  transformation: [
    {
      width: PixelRatio.getPixelSizeForLayoutSize(200).toString(),
      height: PixelRatio.getPixelSizeForLayoutSize(200).toString(),
    },
  ],
});

const coins3Source = ikClient.url({
  path: '/features/landing-pages/footer/coin-3.png',
  transformation: [
    {
      width: PixelRatio.getPixelSizeForLayoutSize(251).toString(),
      height: PixelRatio.getPixelSizeForLayoutSize(251).toString(),
      blur: '5',
    },
  ],
});

export const Background = styled(Stack, {
  context: FooterStyledContext,

  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  overflow: 'hidden',

  children: <Default />,

  variants: {
    backdrop: {
      default: {},
      coins: {
        children: (
          <>
            <Image source={{ uri: coins1Source }} width={200} height={200} position="absolute" top={6} left={-40} />
            <Image source={{ uri: coins2Source }} width={200} height={200} position="absolute" top={-50} right={-18} />
            <Image source={{ uri: coins3Source }} width={251} height={251} position="absolute" top={21} right={-105} />
            <Default />
          </>
        ),
      },
      image: {},
    },
  } as const,
});
