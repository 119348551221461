import { TitleText, styled } from '@arrived/bricks';

import { FooterStyledContext } from './FooterStyledContext';

export const Text = styled(TitleText, {
  context: FooterStyledContext,

  token: 'title.heading.xlarge',
  color: '$interactive.neutral.restedInverted',
  textAlign: 'center',
  // sets up a new stacking context so it is placed in front of the background
  position: 'relative',

  variants: {
    variant: {
      inline: { token: 'title.display.small', textAlign: 'left', maxWidth: 600 },
      stacked: { token: 'title.display.small', maxWidth: 600 },
      condensed: {},
    },
  } as const,
});
