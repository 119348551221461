import { createStyledContext } from '@arrived/bricks';

export interface HeaderStyledContextType {
  alignment?: 'left' | 'center';
  orientation?: 'column' | 'row';
  split?: boolean;
}

export const HeaderStyledContext = createStyledContext<HeaderStyledContextType>({
  alignment: 'left',
  orientation: 'column',
  split: false,
});
