import { forwardRef, useContext, useMemo } from 'react';
import { PixelRatio } from 'react-native';

import {
  BodyText,
  GetProps,
  Image,
  LinearGradient,
  Stack,
  TamaguiElement,
  TitleText,
  VariantSpreadFunction,
  setColorAlpha,
  styled,
  tokens,
  useProps,
  withStaticProperties,
} from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

import { Signup as SignupComponent } from '../../components';
import { HeaderStyledContext, HeaderStyledContextType } from './HeaderStyledContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMainFrameOrientation: VariantSpreadFunction<any, HeaderStyledContextType['orientation']> = (
  orientation,
  config,
) => {
  if (orientation === 'row') {
    let pr = 128;

    if (config.props.split) {
      pr = 80;
    }

    return {
      pl: 128,
      pt: 128,
      pr,
      pb: 128,
      gap: '$8',
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: 'center',
    };
  }
  return {};
};

const MainFrame = styled(Stack, {
  context: HeaderStyledContext,

  width: '100%',
  pl: '$6',
  pt: '$6',
  pr: '$6',
  pb: '$6',

  variants: {
    alignment: {
      left: {},
      center: {
        alignItems: 'center',
      },
    },
    orientation: getMainFrameOrientation,
    split: {
      true: {},
      false: {},
    },
  } as const,
});

const Logo = () => {
  const { alignment, orientation } = useContext(HeaderStyledContext);

  const uri = useMemo(
    () =>
      ikClient.url({
        path: '/standards/logo.svg',
        transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(orientation === 'row' ? 30 : 20).toString() }],
      }),
    [orientation],
  );

  const alignSelf = useMemo(() => {
    if (alignment === 'center') {
      return 'center';
    } else {
      switch (orientation) {
        case 'row':
          return 'flex-start';
        case 'column':
        default:
          return 'center';
      }
    }
  }, [alignment, orientation]);

  return (
    <Image
      alignSelf={alignSelf}
      source={{ uri }}
      height={orientation === 'row' ? 30 : 20}
      width={orientation === 'row' ? 143 : 98}
      contentFit="contain"
    />
  );
};

const Container = styled(Stack, {
  context: HeaderStyledContext,

  gap: '$6',

  variants: {
    orientation: {
      column: {},
      row: {
        gap: 64,
      },
    },
  } as const,
});

const Content = styled(Stack, {
  context: HeaderStyledContext,

  gap: '$4',

  variants: {
    orientation: {
      column: {},
      row: {
        gap: '$6',
      },
    },
  } as const,
});

const Title = styled(TitleText, {
  context: HeaderStyledContext,

  token: 'title.display.small',

  variants: {
    alignment: {
      left: {},
      center: { textAlign: 'center' },
    },
    orientation: {
      column: {},
      row: {
        token: 'title.display.medium',
      },
    },
  } as const,
});

const SubTitle = styled(BodyText, {
  context: HeaderStyledContext,

  token: 'body.default.large',

  variants: {
    alignment: {
      left: {},
      center: { textAlign: 'center' },
    },
  } as const,
});

const Signup = forwardRef<TamaguiElement, Omit<GetProps<typeof SignupComponent>, 'orientation'>>((props, ref) => {
  const { orientation } = useContext(HeaderStyledContext);

  return <SignupComponent ref={ref} orientation={orientation === 'row' ? 'row' : 'column'} {...props} />;
});

export type MainProps = GetProps<typeof MainFrame> & { containerProps?: GetProps<typeof Container> };

export const Main = withStaticProperties(
  MainFrame.styleable<MainProps>((propsIn, ref) => {
    const { children, containerProps, ...rest } = useProps<MainProps>(propsIn);

    return (
      <MainFrame ref={ref} {...rest}>
        <LinearGradient
          start={[0, 0.5]}
          end={[1, 0.5]}
          colors={['transparent', setColorAlpha(tokens.color['primary.light.400'], 0.3)]}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        />
        <Container {...containerProps}>
          <Logo />
          <Content>{children}</Content>
        </Container>
      </MainFrame>
    );
  }),
  {
    Signup,
    SubTitle,
    Title,
  },
);
