import { ValueText, styled } from '@arrived/bricks';

import { TESTIMONIAL_SUB_TITLE_INVESTOR_NAME } from './constants';
import { TestimonialStyledContext } from './TestimonialStyledContext';

export const Name = styled(ValueText, {
  name: TESTIMONIAL_SUB_TITLE_INVESTOR_NAME,

  context: TestimonialStyledContext,

  token: 'value.medium',
  color: '$onSurface.primary.decorative',

  variants: {
    variant: {
      light: {},
      dark: { color: '$onSurface.primary.decorativeAlt' },
    },
  } as const,
});
