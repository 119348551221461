import { Stack, styled, withStaticProperties } from '@arrived/bricks';

import { HEADER_SECONDARY_NAME } from '../constants';
import { HeaderStyledContext } from '../HeaderStyledContext';
import { VacationRental } from './VacationRental';

export const Secondary = withStaticProperties(
  styled(Stack, {
    context: HeaderStyledContext,
    name: HEADER_SECONDARY_NAME,

    variants: {
      orientation: {
        column: {},
        row: {
          flexBasis: 0,
          flexGrow: 1,
          flexShrink: 1,
        },
      },
    } as const,
  }),
  { VacationRental },
);
