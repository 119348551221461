import { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { Button, composeEventHandlers, styled } from '@arrived/bricks';

import { EMAIL_CTA_NAME } from './constants';
import { EmailSchema } from './schema';

const CTAFrame = styled(Button, {
  name: EMAIL_CTA_NAME,
});

export const CTA = CTAFrame.styleable(({ onPress, ...rest }, ref) => {
  const { formState, handleSubmit } = useFormContext<EmailSchema>();

  const { isValid } = formState;

  const handleOnPress = useMemo(
    () => composeEventHandlers(() => handleSubmit(() => {})(), onPress),
    [handleSubmit, onPress],
  );

  return <CTAFrame disabled={!isValid} ref={ref} onPress={handleOnPress} {...rest} />;
});
