import { isWeb } from '@arrived/bricks';

if (__DEV__ && !isWeb && require('expo-constants').default?.expoConfig?.extra?.expo) {
  const { registerRootComponent } = require('expo');
  const { App } = require('./App');

  registerRootComponent(App);
}

export * from './pages';
export * from './sections';
