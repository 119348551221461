import { GetProps, Stack, styled, useProps, withStaticProperties } from '@arrived/bricks';

import { Background } from './Background';
import { FooterStyledContext } from './FooterStyledContext';
import { Signup } from './Signup';
import { Text } from './Text';

const FooterFrame = styled(Stack, {
  context: FooterStyledContext,

  px: '$4',
  py: '$10',
  alignItems: 'center',
  gap: '$6',

  variants: {
    variant: {
      inline: {
        px: 128,
        py: 100,
        row: true,
        gap: 80,
        justifyContent: 'center',
      },
      stacked: {
        px: 128,
        py: 100,
      },
      condensed: {},
    },
    backdrop: {
      default: {},
      coins: {},
      image: {},
    },
  } as const,
});

export type FooterProps = GetProps<typeof FooterFrame>;

export const Footer = withStaticProperties(
  FooterFrame.styleable<FooterProps>((props, ref) => {
    const { children, ...rest } = useProps<FooterProps>(props);

    return (
      <FooterFrame ref={ref} {...rest}>
        {children}
      </FooterFrame>
    );
  }),
  {
    Background,
    Text,
    Signup,
  },
);
