import { useCallback } from 'react';

import { EmailSchema } from '../components/email/schema';
import { PageProps } from './PageProps';

export function useEmailOnSubmit(handleSignup: PageProps['handleSignup']) {
  return useCallback(
    (values: EmailSchema) => {
      handleSignup(values.email);
    },
    [handleSignup],
  );
}
