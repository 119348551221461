import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, PixelRatio } from 'react-native';

import ReanimatedCarousel, { ICarouselInstance } from '@mikehuebner/react-native-reanimated-carousel';

import { BodyText, Stack, StackProps, useMedia, useTheme } from '@arrived/bricks';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';

import { Image } from './Image';
import { PressContentProps } from './PressContentProps';

export type CarouselsProps = PressContentProps & { quoteBoxProps?: StackProps };

export const Carousels = ({
  articles,
  frameLayout,
  quoteBoxProps,
  showingCount: propShowingCount = 5,
}: CarouselsProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quoteCarouselIndex, setQuoteCarouselIndex] = useState(0);
  const [arrowLeftIndexOffset, setArrowLeftIndexOffset] = useState(0);

  const theme = useTheme();

  const media = useMedia();

  const [quoteBoxLayout, setQuoteBoxLayout] = useState<LayoutRectangle>();

  const showingCount = useMemo(() => Math.min(articles.length, propShowingCount), [articles.length]);

  const { autoPlay, imageWidth } = useMemo(() => {
    if (!frameLayout.width || !showingCount) {
      return { autoPlay: false, imageWidth: 200 };
    }
    const imageWidth = frameLayout.width / showingCount;

    return {
      autoPlay: imageWidth < 150,
      imageWidth: imageWidth < 150 ? 180 : Math.min(Math.max(imageWidth, 150), 450),
    };
  }, [frameLayout?.width, showingCount]);

  // When autoplay is toggled on/off, we need to reset the curently viewing/selected index so that the
  // selected one is centered (in the case that we're starting autoplay) or that the centered one is selected
  // (in the case that we're stopping autoplay).
  useEffect(() => {
    if (autoPlay) {
      setCarouselIndex(selectedIndex);
    } else {
      setSelectedIndex(carouselIndex);
      setArrowLeftIndexOffset(0);
    }
  }, [autoPlay]);

  const contentCarouselRef = useRef<ICarouselInstance>(null);

  // This computes the offset for the arrow indicator showing which article is currently being viewed
  const arrowLeft = useMemo(() => {
    const base = (quoteBoxLayout?.width ?? 0) / 2;
    // If we're not autoplaying the carousel, the arrow is always in the center.
    if (autoPlay) {
      return base;
    }

    return base + imageWidth * arrowLeftIndexOffset + (showingCount % 2 === 0 ? -(imageWidth / 2) : 0);
  }, [arrowLeftIndexOffset, autoPlay, quoteBoxLayout?.width, imageWidth, showingCount]);

  const handleOnQuoteBoxLayout = useCallback((e: LayoutChangeEvent) => setQuoteBoxLayout(e.nativeEvent.layout), []);

  const onImageSelect = useCallback(({ animationValue, index }: { animationValue: number; index: number }) => {
    setSelectedIndex(index);
    setArrowLeftIndexOffset(animationValue);
    contentCarouselRef?.current?.scrollTo({ animated: true, index });
  }, []);

  return (
    <>
      <ReanimatedCarousel
        defaultIndex={carouselIndex}
        onSnapToItem={(index) => {
          setCarouselIndex(index);
        }}
        onProgressChange={(_, index) => {
          if (autoPlay) {
            contentCarouselRef?.current?.scrollTo({ index });
          }
        }}
        key={`${autoPlay}`}
        height={36}
        width={imageWidth}
        style={{
          justifyContent: 'center',
          width: '100%',
        }}
        autoPlayInterval={3_000}
        autoPlay={autoPlay}
        enabled={autoPlay}
        data={articles}
        renderItem={({ item: { image }, index, animationValue }) => {
          return (
            <Image
              colored={autoPlay ? index === carouselIndex : index === selectedIndex}
              onPress={!autoPlay ? () => onImageSelect({ animationValue: animationValue.value, index }) : undefined}
              source={ikClient.url({
                path: cdnToImageUrl(image),
                transformation: [{ height: PixelRatio.getPixelSizeForLayoutSize(36).toString() }],
              })}
              hoverStyle={
                !autoPlay
                  ? {
                      cursor: 'pointer',
                      transform: [
                        { scale: 1.0 },
                        { translateX: showingCount % 2 === 0 && !autoPlay ? -(imageWidth / 2) : 0 },
                      ],
                    }
                  : undefined
              }
              transform={[
                { scale: !autoPlay ? 0.97 : 1.0 },
                { translateX: showingCount % 2 === 0 && !autoPlay ? -(imageWidth / 2) : 0 },
              ]}
              transformOrigin={showingCount % 2 === 0 && !autoPlay ? `${-imageWidth / 4}px center` : 'center'}
            />
          );
        }}
        snapEnabled
      />
      <Stack {...quoteBoxProps}>
        <Stack
          br="$2"
          borderColor="$onSurface.neutral.outlineAlt"
          borderWidth="$0.5"
          position="relative"
          onLayout={handleOnQuoteBoxLayout}
          style={{ boxShadow: `0 6px 6px ${theme['onSurface.neutral.container'].val}` }}
        >
          <Stack
            position="absolute"
            borderColor="$onSurface.neutral.outlineAlt"
            borderLeftWidth={20}
            borderLeftColor="transparent"
            borderBottomWidth={20}
            borderRightWidth={20}
            borderRightColor="transparent"
            bg="$onSurface.neutral.defaultInverted"
            transform="translateX(-50%) translateY(-100%)"
            left={arrowLeft}
            style={{
              transition: 'left 500ms',
            }}
          />
          <Stack
            position="absolute"
            borderLeftWidth={18}
            borderRightWidth={18}
            borderBottomWidth={18}
            borderColor="$onSurface.neutral.defaultInverted"
            borderLeftColor="transparent"
            borderRightColor="transparent"
            transform="translateX(-50%) translateY(-100%)"
            left={arrowLeft}
            style={{
              transition: 'left 500ms',
            }}
          />
          <ReanimatedCarousel
            defaultIndex={autoPlay ? carouselIndex : quoteCarouselIndex}
            key={`${autoPlay}`}
            width={quoteBoxLayout?.width ?? 1}
            onSnapToItem={(index) => setQuoteCarouselIndex(index)}
            height={media.gtXs ? 115 : 150}
            enabled={false}
            style={{ justifyContent: 'center', width: '100%' }}
            data={articles}
            renderItem={({ item: { content } }) => (
              <Stack width="100%" height="100%" centered py="$10" px="$4" $gtSm={{ px: '$10' }}>
                <BodyText token="body.default.large" textAlign="center">
                  "{content}"
                </BodyText>
              </Stack>
            )}
            ref={contentCarouselRef}
          />
        </Stack>
      </Stack>
    </>
  );
};
