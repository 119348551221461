import { useCallback } from 'react';

import { FormProvider, UseFormHandleSubmit, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { GetProps, Stack, styled, withStaticProperties } from '@arrived/bricks';

import { EMAIL_FRAME_NAME } from './constants';
import { CTA } from './CTA';
import { Input } from './Input';
import { EmailSchema, emailSchema } from './schema';

const EmailFrame = styled(Stack, {
  name: EMAIL_FRAME_NAME,
});

export type EmailProps = GetProps<typeof EmailFrame> & { onSubmit?: (values: EmailSchema) => void };

export const Email = withStaticProperties(
  EmailFrame.styleable<EmailProps>(({ onSubmit, ...rest }, ref) => {
    const {
      handleSubmit: formHandleSubmit,
      resetField,
      ...formRest
    } = useForm<EmailSchema>({
      resolver: yupResolver(emailSchema),
      mode: 'onTouched',
    });

    const handleSubmit: UseFormHandleSubmit<EmailSchema> = useCallback(
      (validFn, invalidFn) =>
        formHandleSubmit((values) => {
          onSubmit?.(values);
          validFn(values);
          resetField('email');
        }, invalidFn),
      [formHandleSubmit, onSubmit, resetField],
    );

    return (
      <FormProvider handleSubmit={handleSubmit} resetField={resetField} {...formRest}>
        <EmailFrame ref={ref} {...rest} />
      </FormProvider>
    );
  }),
  {
    CTA,
    Input,
  },
);
