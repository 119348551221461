import { createStyledContext, styled, useProps } from '@arrived/bricks';

import { Email } from './Email';

interface SignupStyledContextType {
  colors?: 'default' | 'emphasized';
  orientation?: 'column' | 'row';
}

export const SignupStyledContext = createStyledContext<SignupStyledContextType>({
  colors: 'default',
  orientation: 'column',
});

const SignupFrame = styled(Email, {
  context: SignupStyledContext,

  variants: {
    colors: {
      default: {},
      emphasized: {},
    },
    orientation: {
      column: {},
      row: {
        row: true,
      },
    },
  } as const,
});

const SignupInput = styled(Email.Input, {
  context: SignupStyledContext,

  variants: {
    orientation: {
      column: {},
      row: {
        containerProps: { flex: 1 },
      },
    },
  } as const,
});

const SignupCTA = styled(Email.CTA, {
  context: SignupStyledContext,

  variants: {
    colors: {
      default: {},
      emphasized: { variant: 'emphasized' },
    },
  } as const,
});

export const Signup = SignupFrame.styleable((propsIn, ref) => {
  const props = useProps(propsIn);

  return (
    <SignupFrame ref={ref} {...props}>
      <SignupInput placeholder="Your email" />
      <SignupCTA>Get Started</SignupCTA>
    </SignupFrame>
  );
});
