import { Children, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';

import { Stack, composeEventHandlers, isTamaguiElement, styled, useProps, withStaticProperties } from '@arrived/bricks';

import { Article, ArticleProps } from './Article';
import { Carousels } from './Carousels';
import { PRESS_ARTICLE_NAME, PRESS_FRAME_NAME } from './constants';
import { PressContentProps } from './PressContentProps';

const PressFrame = styled(Stack, {
  name: PRESS_FRAME_NAME,

  gap: '$8',

  variants: {
    variant: {
      blocks: {},
      gallery: {},
      carousel: {},
    },
    showingCount: {
      ':number': () => ({}),
    },
  } as const,
});

export const Press = withStaticProperties(
  PressFrame.styleable((props, ref) => {
    const { children, onLayout, showingCount, variant, ...rest } = useProps(props);

    const [frameLayout, setFrameLayout] = useState<LayoutRectangle>();

    const articles = useMemo(() => {
      return Children.map(children, (child) => {
        if (isTamaguiElement(child, PRESS_ARTICLE_NAME)) {
          const propNames = Object.keys(child.props);
          return propNames.includes('content') && propNames.includes('image') ? (child.props as ArticleProps) : null;
        }

        return null;
      }).filter(Boolean) as ArticleProps[];
    }, [children]);

    const handleOnLayout = useMemo(
      () => composeEventHandlers<LayoutChangeEvent>(onLayout, (event) => setFrameLayout(event.nativeEvent.layout)),
      [onLayout],
    );

    const Content = useCallback(
      (props: PressContentProps) => {
        switch (variant) {
          case 'carousel':
            return <Carousels quoteBoxProps={{ px: '$4', $gtXs: { px: '$10' } }} {...props} />;
          case 'gallery':
            return null;
          case 'blocks':
          default:
            return null;
        }
      },
      [variant],
    );

    return (
      <PressFrame ref={ref} onLayout={handleOnLayout} {...rest}>
        {frameLayout && <Content articles={articles} frameLayout={frameLayout} showingCount={showingCount} />}
      </PressFrame>
    );
  }),
  {
    Article,
  },
);
