import { createStyledContext } from '@arrived/bricks';

export interface FooterStyledContextType {
  backdrop?: 'default' | 'coins' | 'image';
  variant?: 'inline' | 'stacked' | 'condensed';
}

export const FooterStyledContext = createStyledContext<FooterStyledContextType>({
  backdrop: 'default',
  variant: 'condensed',
});
