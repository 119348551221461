import { forwardRef } from 'react';

import { Image as BricksImage, ImageProps as BricksImageProps } from '@arrived/bricks';

export type ImageProps = BricksImageProps & { colored?: boolean };

export const Image = forwardRef<BricksImage, ImageProps>(({ colored, ...rest }, ref) => {
  return (
    <BricksImage
      ref={ref}
      height={36}
      contentFit="contain"
      style={{
        filter: colored ? 'grayscale(0) opacity(1.0)' : 'grayscale(1) opacity(0.4)',
        transition: 'filter 100ms, transform 100ms',
      }}
      {...rest}
    />
  );
});
