import { FormattedMessage } from 'react-intl';

import { BodyText, styled } from '@arrived/bricks';

import { TESTIMONIAL_SUB_TITLE_INVESTOR_SINCE_NAME } from './constants';
import { TestimonialStyledContext } from './TestimonialStyledContext';

const SinceText = styled(BodyText, {
  name: TESTIMONIAL_SUB_TITLE_INVESTOR_SINCE_NAME,

  context: TestimonialStyledContext,

  token: 'body.default.medium',
  color: '$onSurface.neutral.muted',

  variants: {
    variant: {
      light: {},
      dark: { color: '$onSurface.neutral.outline' },
    },
  } as const,
});

export const Since = SinceText.styleable(({ children, ...rest }, ref) => (
  <SinceText ref={ref} {...rest}>
    <FormattedMessage id="landing-page.investor-since" values={{ date: children }} />
  </SinceText>
));
