import {
  Gallery,
  GetProps,
  LinearGradient,
  Stack,
  StarIcon,
  TitleText,
  ValueText,
  createStyledContext,
  styled,
  useProps,
} from '@arrived/bricks';
import { LazyImage } from '@arrived/bricks-common';

interface VacationRentalContextType {
  condensed?: boolean;
}

const VacationRentalStyledContext = createStyledContext<VacationRentalContextType>({ condensed: false });

const VacationRentalFrame = styled(Stack, {
  context: VacationRentalStyledContext,

  width: '100%',
  height: '100%',
  borderColor: '$onSurface.neutral.default',
  borderBottomWidth: '$10',

  variants: {
    condensed: {
      true: { borderBottomWidth: '$6' },
      false: {},
    },
  } as const,
});

const Content = styled(Stack, {
  context: VacationRentalStyledContext,

  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',

  pt: '$10',
  px: '$10',
  gap: '$1',

  variants: {
    condensed: {
      true: { pt: '$6', px: '$6' },
      false: {},
    },
  } as const,
});

const Gradient = styled(LinearGradient, {
  context: VacationRentalStyledContext,

  colors: ['transparent', '$onSurface.neutral.default'],
  locations: [0, 0.8],
  position: 'absolute',
  left: 0,
  right: 0,
  height: 100,
  bottom: 0,
});

export interface VacationRentalInfo {
  image: string;
  rating: number;
  name: string;
}

export type VacationRentalProps = Omit<GetProps<typeof VacationRentalFrame>, 'children'> & {
  vacationRentals: VacationRentalInfo[];
};

export const VacationRental = VacationRentalFrame.styleable<VacationRentalProps>((propsIn, ref) => {
  const { condensed, vacationRentals, ...rest } = useProps<VacationRentalProps>(propsIn);

  return (
    <VacationRentalFrame ref={ref} condensed={condensed} {...rest}>
      {/* {image && <LazyImage position="absolute" top={0} left={0} right={0} bottom={0} source={{ uri: image }} />} */}
      <Gallery items={vacationRentals ?? []} position="absolute" top={0} left={0} right={0} bottom={0}>
        <Gallery.Carousel<VacationRentalInfo>
          flex={1}
          ReanimatedCarouselProps={{ enabled: (vacationRentals?.length ?? 0) > 1 }}
          renderItem={({ index, item: { image, rating, name } }) => (
            <>
              <LazyImage
                key={index}
                source={{ uri: image }}
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
              />
              <Gradient />
              <Content>
                <TitleText token="title.heading.large" color="$interactive.neutral.restedInverted">
                  {name}
                </TitleText>
                <Stack row gap="$3" alignItems="center">
                  <Stack row gap="$0.5">
                    {Array(...new Array(Math.ceil(rating ?? 5))).map((_, idx) => (
                      <>
                        <StarIcon key={idx} color="$onSurface.secondary.low" size="xs" />
                      </>
                    ))}
                  </Stack>
                  <Stack row gap="$1">
                    <ValueText token="value.small" color="$interactive.neutral.restedInverted">
                      {rating} stars
                    </ValueText>
                    <ValueText token="value.small.alt" color="$interactive.neutral.restedInverted">
                      on Airbnb
                    </ValueText>
                  </Stack>
                </Stack>
              </Content>
            </>
          )}
        >
          <Gallery.Carousel.Arrows />
          <Gallery.Carousel.Pagination right={condensed ? '$6' : '$10'} left="unset" bottom={0} />
        </Gallery.Carousel>
      </Gallery>
    </VacationRentalFrame>
  );
});
