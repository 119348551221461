import YouTube from 'react-youtube';

import { GetProps, Stack, createStyledContext, styled, useProps } from '@arrived/bricks';

import { Testimonial } from '../../../components';

export type TestimonialVideoProps = GetProps<typeof TestimonialVideoFrame> & {
  youtubeId: string;
  name: string;
  since: string;
  quote: string;
};

const TestimonialVideoStyledContext = createStyledContext<{ orientation?: 'row' | 'column' | 'row-reverse' }>({
  orientation: 'column',
});

const TestimonialVideoFrame = styled(Stack, {
  context: TestimonialVideoStyledContext,

  gap: '$4',

  variants: {
    orientation: {
      column: {},
      row: {
        alignItems: 'center',
        row: true,
        gap: 0,
      },
      'row-reverse': {
        alignItems: 'center',
        row: true,
        flexDirection: 'row-reverse',
        gap: 0,
      },
    },
  } as const,
});

const VideoContainer = styled(Stack, {
  context: TestimonialVideoStyledContext,

  overflow: 'hidden',
  br: '$2',

  variants: {
    orientation: {
      column: {},
      row: {
        mx: '$10',
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
      },
      'row-reverse': {
        mx: '$10',
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
      },
    },
  } as const,
});

const TestimonialStyled = styled(Testimonial, {
  context: TestimonialVideoStyledContext,

  variant: 'dark',

  variants: {
    orientation: {
      column: {},
      row: {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
      },
      'row-reverse': {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
      },
    },
  } as const,
});

export const TestimonialVideo = TestimonialVideoFrame.styleable<TestimonialVideoProps>((props, ref) => {
  const { youtubeId, name, since, quote, orientation, ...rest } = useProps<TestimonialVideoProps>(props);

  return (
    <TestimonialVideoFrame ref={ref} orientation={orientation} {...rest}>
      <VideoContainer>
        <YouTube
          videoId={youtubeId}
          opts={{ playerVars: { rel: 0, controls: 0 }, width: '100%', height: '100%' }}
          style={{ width: '100%', aspectRatio: '16/9' }}
        />
      </VideoContainer>
      <TestimonialStyled variant="dark">
        <Testimonial.Quote size={orientation === 'column' || !orientation ? 'small' : 'large'}>
          {quote}
        </Testimonial.Quote>
        <Testimonial.Name>{name}</Testimonial.Name>
        <Testimonial.Since>{since}</Testimonial.Since>
      </TestimonialStyled>
    </TestimonialVideoFrame>
  );
});
