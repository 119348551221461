import { Background, Stack, StackProps, styled } from '@arrived/bricks';

import { TestimonialVideo, TestimonialVideoProps } from './TestimonialVideo';

export type TestimonialVideoCarouselProps = StackProps & {
  testimonials: TestimonialVideoProps[];
};

const Wrapper = styled(Stack, {
  px: '$4',
});

const Container = styled(Stack, {
  br: '$4',

  px: '$4',
  py: '$4',
  overflow: 'hidden',

  $gtXs: {
    py: '$12',
  },
});

export const TestimonialVideoCarousel = ({ testimonials, ...rest }: TestimonialVideoCarouselProps) => {
  return (
    <Wrapper {...rest}>
      <Container>
        <Background.Noise position="absolute" top={0} left={0} right={0} bottom={0} />
        <TestimonialVideo $gtSm={{ orientation: 'row' }} {...testimonials[0]} />
      </Container>
    </Wrapper>
  );
};
