import { createStyledContext } from '@arrived/bricks';

interface TestimonialStyledContextType {
  alignment?: 'left' | 'right' | 'center';
  variant?: 'light' | 'dark';
}

export const TestimonialStyledContext = createStyledContext<TestimonialStyledContextType>({
  alignment: 'left',
  variant: 'light',
});
