import { TitleText, styled } from '@arrived/bricks';

import { TESTIMONIAL_QUOTE_NAME } from './constants';
import { TestimonialStyledContext } from './TestimonialStyledContext';

const QuoteText = styled(TitleText, {
  name: TESTIMONIAL_QUOTE_NAME,

  context: TestimonialStyledContext,

  token: 'title.heading.medium',
  color: '$interactive.neutral.rested',

  variants: {
    alignment: {
      left: {},
      right: { textAlign: 'right' },
      center: { textAlign: 'center' },
    },
    size: {
      small: {},
      large: { token: 'title.heading.large' },
    },
    variant: {
      light: {},
      dark: { color: '$interactive.neutral.restedInverted' },
    },
  } as const,
});

export const Quote = QuoteText.styleable(({ children, ...rest }, ref) => (
  <QuoteText ref={ref} {...rest}>
    "{children}"
  </QuoteText>
));
