import { GetProps, Stack, styled } from '@arrived/bricks';
import { LazyImage } from '@arrived/bricks-common';

import { TESTIMONIAL_IMAGE_NAME } from './constants';

const ImageFrame = styled(Stack, {
  name: TESTIMONIAL_IMAGE_NAME,

  br: '$full',
  h: 58,
  w: 58,
  overflow: 'hidden',

  variants: {
    size: {
      small: {},
      large: { h: 80, w: 80 },
    },
  } as const,
});

export type ImageProps = GetProps<typeof ImageFrame> & { source: string };

export const Image = ImageFrame.styleable<ImageProps>(({ source, ...rest }, ref) => {
  return (
    <ImageFrame ref={ref} {...rest}>
      <LazyImage h="100%" w="100%" source={{ uri: source }} />
    </ImageFrame>
  );
});
