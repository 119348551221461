import { useMemo } from 'react';

import { Stack, parseRootChildren, styled, useProps, withStaticProperties } from '@arrived/bricks';

import {
  TESTIMONIAL_FRAME_NAME,
  TESTIMONIAL_SUB_TITLE_INVESTOR_NAME,
  TESTIMONIAL_SUB_TITLE_INVESTOR_SINCE_NAME,
  TESTIMONIAL_SUB_TITLE_NAME,
} from './constants';
import { Image } from './Image';
import { Name } from './Name';
import { Quote } from './Quote';
import { Since } from './Since';
import { TestimonialStyledContext } from './TestimonialStyledContext';

const TestimonialFrame = styled(Stack, {
  name: TESTIMONIAL_FRAME_NAME,

  context: TestimonialStyledContext,

  gap: '$6',

  variants: {
    alignment: {
      left: {},
      center: { alignItems: 'center' },
      right: { alignItems: 'flex-end' },
    },
    variant: {
      dark: {},
      light: {},
    },
  } as const,
});

const SubTitleFrame = styled(Stack, {
  name: TESTIMONIAL_SUB_TITLE_NAME,

  context: TestimonialStyledContext,

  variants: {
    alignment: {
      left: {},
      center: { alignItems: 'center' },
      right: { alignItems: 'flex-end' },
    },
  } as const,
});

export const Testimonial = withStaticProperties(
  TestimonialFrame.styleable((props, ref) => {
    const { children: childrenIn, ...rest } = useProps(props);

    const {
      [TESTIMONIAL_SUB_TITLE_INVESTOR_NAME]: Name,
      [TESTIMONIAL_SUB_TITLE_INVESTOR_SINCE_NAME]: Since,
      children,
    } = useMemo(
      () =>
        parseRootChildren(childrenIn, [TESTIMONIAL_SUB_TITLE_INVESTOR_NAME, TESTIMONIAL_SUB_TITLE_INVESTOR_SINCE_NAME]),
      [childrenIn],
    );

    return (
      <TestimonialFrame ref={ref} {...rest}>
        {children}
        {(Name || Since) && (
          <SubTitleFrame>
            {Name}
            {Since}
          </SubTitleFrame>
        )}
      </TestimonialFrame>
    );
  }),
  {
    Image,
    Name,
    Quote,
    Since,
  },
);
